import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/images/logo-light.svg";
import ytIcon from "../../assets/icons/youtube.svg";
import twIcon from "../../assets/icons/twitter.svg";
import ghIcon from "../../assets/icons/github.svg";
import MapleLeaf from "../../assets/icons/maple-leaf.svg";
import linkedInIcon from "../../assets/icons/linkedin.svg"; // Import LinkedIn icon


import File from "../../assets/files/white-papper.pdf";

const Footer = () => {
  const footerMenu = [
    {
      title: 'TCXC Marketplace Enablement',
      items: [
        { title: 'Voice Exchange', link: '/cloud', type: 'link' },
        { title: 'Messaging Exchange', link: '/cloud', type: 'link' },
        { title: 'Numbers Exchange', link: '/cloud', type: 'link' },
        { title: 'HLR Exchange', link: '/cloud', type: 'link' },
      ],
    },
    {
      title: 'TCXC Wholesale Platform',
      items: [
        { title: 'White Paper', link: File, type: 'href', target: '_blank' },
        { title: 'Book Demo', link: '/contact', type: 'link' },
        { title: 'Case Study', link: 'https://blog.telecomsxchange.com/neutrafix-telecom-marketplace/', type: 'link' },
        { title: 'IoT AAA', link: '/iot', type: 'link' },
        { title: 'TeleanalytiX (Ai)', link: '/teleanalytixai', type: 'link' },
        { title: 'Open Text Shield Model (Ai)', link: 'https://github.com/TelecomsXChangeAPi/OpenTextShield/blob/main/Installation.md', type: 'link' },
        { title: 'TCXC Intelligent Dynamic Routing (Ai)', link: 'https://tcxc-idr-v1.replit.app/redoc', type: 'link' },
      ],
    },
    {
      title: 'Resources',
      items: [
        { title: 'TCXC Blog', link: 'https://blog.telecomsxchange.com', type: 'link' },
        { title: 'Get Support', link: '/contact', type: 'href' },
        { title: 'Open Text Shield', link: 'https://ots.telecomsxchange.com', type: 'href' },
        { title: 'API Docs', link: 'https://apidocs.telecomsxchange.com', type: 'href' },
        { title: 'Number Lookup', link: 'https://tools.telecomsxchange.com', type: 'href' },
        { title: 'Privacy', link: '/privacy', type: 'link' },
        { title: 'Terms', link: '/terms', type: 'link' },
        { title: 'Telecom GPT', link: 'https://chat.openai.com/g/g-eSwyzwwDV-telecom-gpt', type: 'href' },
        { title: 'TCXC GPT', link: 'https://chatgpt.com/g/g-tEGI40th6-telecomsxchange-tcxc', type: 'href' },
      ],
    },
    {
      title: 'About us',
      items: [
        { title: 'Linkedin', link: 'https://www.linkedin.com/company/telecomsxchange/', type: 'href' },
        { title: 'Blog', link: 'https://blog.telecomsxchange.com', type: 'href' },
        { title: 'Github', link: 'https://github.com/telecomsxchangeapi/', type: 'href' },
       
      ],
    },
    {
      title: 'Contact Us',
      items: [
        {
          title: 'Canada',
          link: '/#',
          type: 'text',
          render: () => (
            <div className="app-footer-contact-country">
              <div className="leaf-and-text">
                <img src={MapleLeaf} alt="Maple Leaf" className="maple-leaf-icon" />
                <div className="contact-details">
                  <p><strong>TelecomsXChange Canada Inc.</strong></p>
                  <p>BITS License # 8190-T300-202405943</p>
                  <p>4145 N Service Rd, 2nd Floor</p>
                  <p>Burlington, ON, Canada L7L 6A3</p>
                  <p>
                    <a href="tel:+13652972668">+1-365-297-2668</a>
                  </p>
                  <p>
                    <a href="mailto:canada@telecomsxchange.com">
                      canada@telecomsxchange.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ),
        },
        {
          title: 'U.S.A',
          link: '/#',
          type: 'text',
          render: () => (
            <div className="app-footer-contact-country">
              <div className="contact-details">
                <p><strong>TelecomsXChange LLC</strong></p>
                <p>FCC License # ITC-214-20150519-00126</p>
                <p>1800 N Bayshore Drive, STE CU1</p>
                <p>Miami, FL, 33132 - U.S.A</p>
                <p>
                  <a href="tel:+19542463356">+1-954-246-3356</a>
                </p>
                <p>
                  <a href="mailto:info@telecomsxchange.com">
                    info@telecomsxchange.com
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <footer className="app-footer">
      <div className="container">
        <div className="app-footer-nav">
          <div className="app-footer-logo">
            <img src={Logo} alt="TelecomsXChange Logo" />
          </div>
          <ul className="app-footer-social">
            <li>
              <a target="_blank" href="https://twitter.com/telecomsxchange" rel="noreferrer">
                <img src={twIcon} alt="Twitter" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://github.com/telecomsxchangeAPI" rel="noreferrer">
                <img src={ghIcon} alt="Github" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.youtube.com/telecomsxchangetrading" rel="noreferrer">
                <img src={ytIcon} alt="YouTube" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.linkedin.com/company/telecomsxchange/" rel="noreferrer">
                <img src={linkedInIcon} alt="LinkedIn" className='linkedin-icon' />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container">
        <div className="app-footer-menu">
          {footerMenu.map((item) => (
            <ul key={item.title}>
              <li>
                <span>{item.title}</span>
              </li>
              {item.items.map((elem) => (
                <li key={elem.title}>
                  {elem.type === 'text'
                    ? elem.render
                      ? elem.render()
                      : <p>{elem.title}</p>
                    : elem.type === 'link'
                    ? <Link to={elem.link}>{elem.title}</Link>
                    : <a target={elem.target ?? '_self'} href={elem.link} rel="noreferrer">{elem.title}</a>}
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

// components
import SmallHero from "../../components/SmallHero";
import Input from '../../components/Input';
import Button from '../../components/Button';

// sections
import Header from '../../sections/Header';
import Footer from '../../sections/Footer';
import Form from "../../sections/Form";

// images
import phoneIcon from "../../assets/icons/phone.svg";
import mailIcon from "../../assets/icons/mail.svg";

import axios from "axios"; 
import { contactApi } from '../../config';

import { MainContext } from '../../App';
import Loader from '../../components/Loader';

const Contact = () => {

    const fields = [
            {
                name: 'first_name',
                title: 'First Name',
                type: 'text',
            },
            {
                name: 'last_name',
                title: 'Last Name',
                type: 'text',
            },
            {
                name: 'phone',
                title: 'Phone',
                type: 'phone',
                country: 'us',
                placeholder: '+1 (555) 987-6543',
            },
            {
                name: 'email',
                title: 'E-mail',
                type: 'email',
            },
            {
                name: 'company',
                title: 'Company Name',
                type: 'text',
            },
            {
                name: 'role',
                title: 'Role',
                type: 'text',
            },
            {
                title: 'What are you interested in?',
                name: 'interested',
                type: 'radio',
                fields: ['TCXC SaaS - Cloud Exchange', 'TCXC - PaaS', 'Other']
            },
            {
                name: 'message',
                title: 'Message',
                placeholder: 'Write your message',
                type: 'textarea',
            }
    ];

    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(true);
    const [invalids, setInvalids] = useState([]);
    const [loading, setLoading] = useState(false);

    const [form, setForm] = useState({
        interested: fields.find(el => el.name == 'interested').fields[0],
        first_name: '',
        last_name: '',
        email: '',
        company: '',
        message: '',
        role: '',
        phone: ''
    });

    useEffect(() => {
        let maps = (Object.keys(form).every(el => (form[el] !== '' && form[el] !== null && form[el] !== false)) && invalids.length == 0)
        setIsDisabled(!maps);
    }, [form, invalids]);

    const { setModal } = useContext(MainContext);

    const sendForm = async () => {
        try {
            setIsDisabled(true);
            setLoading(true);

            let {data} = await axios.get(contactApi, {params: form});
    
            if (data.status == 'success') {
                setModal({type: 'success', text: 'Thanks. Your form successfully send!'})
                navigate('/');
            }
            else {
                setModal({type: 'error', text: 'Error... Your form was not submitted'})

            }
            setIsDisabled(false);
            setLoading(false);
        }
        catch (e) {
            console.error(e);
        }
    }

    return (
        <div className="app-contact">
            <Header/>
            <SmallHero children={
                <>
                    <div className="small-hero-text">
                        <h1>Contact Us</h1>
                        <p>Any question or remarks? Just write us a messages</p>
                    </div>

                    <Form sidebar={
                            <div className='app-contact-info'>
                                <h3>Contact Information</h3>
                                <p>Fill up the form and our Team will get back to you withing 24 hours.</p>
                                <span>TelecomXChange LLC</span>
                                <span>1800 N Bayshore Dr, Suite CU1</span>
                                <span>Miami FL,33132 - USA</span>

                                <ul className="app-contact-info-social">
                                    <li className='app-contact-info-social-item'>
                                        <a href="tel:+1-954-246-3356">
                                            <div className="app-contact-info-social-item-icon">
                                                <img src={phoneIcon} alt="" />
                                            </div>
                                            <p>+1-954-246-3356</p>
                                        </a>
                                    </li>
                                   
                                    <li className='app-contact-info-social-item'>
                                        <a href="mailto:info@telecomsxchange.com">
                                            <div className="app-contact-info-social-item-icon">
                                                <img src={mailIcon} alt="" />
                                            </div>
                                            <p>info@telecomsxchange.com</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        }

                        content={
                            <div className="app-contact-wrap">
                                {
                                    loading ?
                                        <Loader />
                                    :
                                    <>
                                        <div className="app-contact-inputs">
                                            {
                                                fields.map((item, index) => (
                                                    <Input 
                                                        key={index}
                                                        type={item.type} 
                                                        dropdown={item.dropdown ?? false} 
                                                        title={item.title} 
                                                        country={item.country ?? ''}
                                                        flagsWith={false}
                                                        placeholder={item.placeholder ?? ''}
                                                        name={item.name}
                                                        fields={item.fields ?? false}
                                                        invalids={invalids}
                                                        setInvalids={setInvalids}

                                                        form={form}
                                                        setForm={setForm}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <Button disabled={isDisabled} type="submit" onClick={sendForm} text="Send"/>
                                    </>
                                }
                            </div>
                        }
                    />
                </>
            } className="circle cut"/>
            <Footer/>
        </div>
    )
}

export default Contact;